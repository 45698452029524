import React from "react";
import Div from "app/components/Div";
import { useGetPersonalizedPlaylistForScheduleQuery } from "services/graphql";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import ClassRowResults from "./ClassRowResults";

interface Props {
  addClassToSchedule: (classId: number) => void;
  addedClassIds: number[];
}

export const RecommendedClasses = ({
  addClassToSchedule,
  addedClassIds,
}: Props) => {
  const { data, loading, error } = useGetPersonalizedPlaylistForScheduleQuery();

  if (loading) {
    return <LoaderCentered />;
  }

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }

  const { myPersonalizedPlaylist } = data;

  if (!myPersonalizedPlaylist) {
    return null;
  }

  const { personalizedPlaylist } = myPersonalizedPlaylist;

  if (!personalizedPlaylist) {
    return null;
  }

  return (
    <Div overflow="auto" height="100%" width="100%">
      {personalizedPlaylist.map(classData => {
        const isClassAdded = addedClassIds?.includes(parseInt(classData.id));

        return (
          <ClassRowResults
            classData={classData}
            isClassAdded={isClassAdded}
            addClassToSchedule={addClassToSchedule}
          />
        );
      })}
    </Div>
  );
};
